.user-info {
  @extend .text-center;

  > div {
    @extend .flex-wrap;
  }
  
  .user-img {
    @include custom-height-width(45px, 45px);
    @extend .circle;
    @include border(border, 1px solid $white);
    @extend .object-fit-cover;
    @extend .overflow-hidden;
    @extend .margin-auto;
    @include custom-box-shadow(#00000024 3.5px 4px 30px);
    
    img {
      @include custom-height-width(100%, 100%);
    }
  }
  
  h6 {
    @extend .f-14;
    @extend .margin-y-0;
  }
  
  p {
    @extend .f-13;
    @include color($text-dark-gray);
  }
  
  button {
    @extend .min-width-110;
    @extend .margin-auto;
    
    i {
      @extend .margin-right-15;
    }
  }
}

.user-profile {
  .img-wrapper {
    @extend .margin-right-10;
    @include image-wrapper-custom;
    @include custom-height-width(35px, 35px);
    @include custom-box-shadow(0px 0px 9px 1px #7a919930);
    @include border(border, 1.5px solid $white);
    @extend .circle;
  }
  
  .dropdown-menu83 {
    @extend .min-width-370;
    @extend .padding-20;
    @extend .border-1;
    @include custom-height(calc(100vh - 0px));
    @include custom-border-radius(8px, 0px, 0px, 8px);
    @extend .bg-white;
    @include custom-box-shadow(6px 11px 26px -3px #00000052);
    @include custom-position-fixed-top-right(0px, 0px);
    @include custom-z-index(2);
    transform: translate(100%);
    
    button {
      @include background-and-textColor(#e64f4f14, $danger-color !important);
      @extend .f-16;
      @extend .border-0;
      @include custom-height-width(32px, 32px);
      @include custom-min-height(32px);
      @include custom-min-width(32px);
      @extend .border-radius-6;
      @extend .position-absolute;
      @include custom-left-top(12px, 12px);
      
      i {
        @extend .text-danger;
        @include custom-margin-right(0px !important);
      }
    }
    
    .dropdown-item83 {
      @extend .align-items-center;
      @extend .margin-bottom-15;
      @extend .padding-bottom-15;
      @extend .border-bottom-1;
      
      .img-wrapper {
        @include custom-height-width(150px, 150px);
        @include margin(20px, auto, 20px, auto);
        @extend .circle;
        @extend .object-fit-contain;
        @extend .overflow-hidden;
        
        img {
          @extend .w-100;
        }
      }
      
      &:last-of-type {
        @extend .margin-bottom-0;
        @extend .border-bottom-0;
      }
      
      h6 {
        @include margin(0px, 10px, 0px, 0px);
      }
      
      span {
        @include size-weight(13px, 500);
        @extend .align-items-center;
      }
      
      .badge {
        @include background-and-textColor($danger-color, $white);
        @extend .border-radius-30;
        @extend .min-width-45;
        @extend .padding-5;
      }
      
      i {
        @extend .margin-right-10;
        @include size-color(16px, $themeColorPrimary);
      }
    }
    
    .user-profile-img {
      @include custom-height-width(150px, 150px);
      @include margin(20px, auto, 20px, auto);
      @extend .circle;
      @extend .object-fit-contain;
      @extend .overflow-hidden;
      
      img {
        @extend .w-100;
      }
    }
  }
}

.sliding-dropdown83-show {
  animation: moveFromRightToShow 0.6s ease both;
}

.sliding-dropdown83-hide {
  animation: moveFromRightToHide 0.6s ease both;
}

.logout83 {
  @extend .padding-0;
  @extend .margin-0;

  i {
    @include custom-margin-right(0px !important);
  }

  &:hover {
    @include color($danger-color !important);
  }
}