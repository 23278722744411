.subscription-card-card83 {
    text-align: center;
    padding: 0 255px;
    height: 100%;
    overflow: auto;
    h2 {
        @include size-weight-color(22px, 600, $text-color);
        margin: 0 auto 16px auto;
        width: 580px;
        overflow-wrap: break-word;  
        word-wrap: break-word; 
        word-break: break-word;
    }
    h3 {
        @include size-weight-color(16px, 400, $content-color-light);
        width: 580px;
        overflow-wrap: break-word;  
        word-wrap: break-word; 
        word-break: break-word;
        margin: 0 auto 16px auto;
    }
    .tabs-toggle {
        background-color: $card-light-blue-bg;
        display: inline-flex;
        margin: 22px auto;
        .button83,.button83-default {
            min-width: 150px !important;
        }
        .button83-default {
            border-radius: 0 5px 5px 0;
            color: $content-color-light;
            &:hover {
                background-color: #e8fee8;
                color: #26b24c;
                border-radius: 5px;
            }
        }
    }
    .subsequent-text {
        @include size-weight(16px,500);
    }
    .pricing-list {
        flex-wrap: wrap;
        justify-content: center;
        li {
            padding: 0 16px;
            cursor: pointer;
            position: relative;
            .pricing-card-card83 {
                text-align: left;
                .card83-body {
                    padding: 20px;
                    z-index: 1;
                    min-height: 460px;
                    max-height: 460px;
                    overflow: auto; 
                    padding-bottom: 50px;   
                    h6{
                        @include size-weight-color(16px,600,$content-color-light); 
                        margin: 0 0 16px 0;
                        &.title {
                            @include size-weight-color(24px,600,$text-color);
                            text-transform: capitalize
                        }
                    } 
                    h5 {
                        @include size-weight-color(30px,700,$text-color);
                        margin: 0 0 16px 0;
                    }
                    p {
                        @include size-weight-color(14px,400,$content-color-light);
                        margin: 0 0 16px 0;
                    }
                    .button83-outlined-primary {
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        right: 10px;
                    }
                    .feature-list {
                        margin-bottom: 32px;
                        li {
                            padding: 0;
                            h1 {
                                .feature-icon {
                                    margin-right: 10px;
                                }
                                
                            }
                            p {
                                font-size: 12px;
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
                &:after {
                    content: "";
                    height: 55px;
                    height: 65px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 0;
                    border-radius: 8px 8px 0 0;
                }
                &.gradient-blue {
                    &:after {
                        background-image: linear-gradient(#33a0c73b, white);                
                    }
                }
                &.gradient-green {
                    &:after {
                        background-image: linear-gradient(#50b05229, white);                
                    }
                }
                &.gradient-pink {
                    &:after {
                       background-image: linear-gradient(#eca9b836, white);                
                    }
                }
                &:hover {
                    .card83-body { 
                        h6.title,h5 {
                            color: $success;
                        }
                        .button83-outlined-primary {
                            background-color: $themeColorPrimary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    .pricing-table-card83 {
        .card83-body {
            padding-right: 0;
            padding-bottom: 0;
        }
        .table-bordered {
            border-collapse: collapse;
            thead {
                tr th {
                    text-align: center;
                    border-top: 0;
                    &:first-child {
                        border-left: 0;
                    }
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
            tbody {
                tr {
                    th,td {
                        &:first-child {
                            border-left: 0;
                        }
                        &:last-child {
                            border-right: 0;
                        }
                    }
                    &:last-child {
                        th,td {
                            border-bottom: 0;
                        }
                    }
                }
            }
            tr {
                th,td {
                    padding: 20px 16px;
                    border: 1px solid $border-color;
                    text-align: left;
                }
                th {
                    h5 {
                        @include size-weight-color(16px,500,$text-color);
                        margin: 0;
                    }
                    h6 {
                        @include size-weight-color(20px,700,$text-color);  
                        margin: 16px auto;
                        width: fit-content;
                        padding-bottom: 8px;
                        position: relative;
                        &::after {
                            content: "";
                            height: 5px;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border-radius: 20px;
                        }   
                        &.border-bottom-blue {
                            &::after {
                                background: $blue;
                            }
                        }
                        &.border-bottom-success {
                            &::after {
                                background: $success;
                            }
                        }
                        &.border-bottom-pink {
                            &::after {
                                background: $pink;
                            }
                        }
                    }
                    .button83 {
                        margin: auto;
                    }
                }
                td {
                    @include size-weight-color(14px,400,$content-color-light);
                    text-align: left;
                    p {
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.provider-card-card83 {
    height: calc(100vh - 173px);
    .table {
        tbody {
            td {
                @include size-weight-color(13px,500,$content-color);
            }
        }
    }
}

.timeline-card-card83 {
    height: 100%;
    overflow: auto;
    h1 {
        margin: 10px auto;
        @include size-weight-color(15px,600,$text-color);
    }
    .timeline-wrapper {
        width: 650px;
        margin: auto;
        ul.timeline-list {
            padding: 0;
            .timeline-item {
                justify-content: space-between;
                align-items: center;
                padding-left: 40px;
                position: relative;
                padding-bottom: 25px;
                &:not(:last-of-type)::before {
                    content: "";
                    height: 100%;
                    border-left: 1px solid $border-color;
                    position: absolute;
                    top: 0;
                    left: 10px;
                }
                .timeline-status {
                    @include circle(20px,20px); 
                    @include custom-position-top-left(0,0); 
                    @include flex-align-justify(center,center);
                    font-size: 14px;
                    border: 1px solid;
                    &.status-success {
                        color: $success;
                        background: #dbffdb;
                        border-color: $success;
                    }
                    &.status-danger {
                        color: $danger-color;
                        background: #ffe2e2;
                        border-color: $danger-color;
                    }
                }
                h6 {
                    margin: 0 0 8px 0;
                    @include size-weight-color(14px,600,$content-color);
                }
                p {
                    @include size-weight-color(12px,500,$content-color-light);
                }
                h4 {
                    margin: 0 0 4px 0;
                    @include size-weight-color(14px,600,$content-color);
                }
            }
        }
    }
}