.error-page {
  @extend .h-100vh;
  @extend .overflow-hidden;

  .error-content {
    @extend .text-center;
    @extend .center;
    @include custom-max-width(350px);

    h1 {
      @include margin(-20px, 0px, -15px, 0px);
      @include size-weight-color(150px, 800, $text-dark-gray);
      text-shadow: -6px 0 0 $text-color;
    }

    button {
      @extend .margin-auto;
      @extend .min-width-90;
    }

    img {
      @extend .width-300;
    }

    p {
      @include size-weight-color(14px, 600, $text-color);
      @extend .margin-bottom-30;
    }
  }
}