.searc83-form {
    @include custom-margin-bottom(0px !important);
    @extend .padding-0;
    @include background($transparent !important);
    @include custom-box-shadow(none !important);

}

.search83 {
    @extend .position-relative;
    @extend .margin-right-10;
    @extend .border-radius-10;

    &.form83-group {
        @include custom-margin-bottom(0px !important);

        button {
          @extend .padding-0;
          @include background($transparent !important);
          @include  padding(0px, 4px, 0px, 0px);
          @extend .border-0;
        }
    }
    
    .search83-input {
      @extend .w-100;
      @extend .f-14;
      @include padding(12px, 45px, 12px, 15px);
      @include background-and-textColor(#F3F6FA, $text-color);
      @extend .border-radius-8;
      @extend .border-0;
      @include transition-3s;
      @include custom-height(40px);
      &:focus {
        @extend .border-1;
        @extend .outline-none;
        @include border(border, 1px solid $border-color-dark !important);
      }
  
      &:hover {
        @include border(border, 1px solid $border-color-dark !important);
      }
    }
    .button83 {
      @extend .f-17;
      @include padding(6px, 9px, 6px, 9px);
      @extend .position-absolute;
      @include custom-z-index(10);
      @include transform-top-right-center-align;
      @include color($disabled-color);
      @extend .box-shadow-0;
      
      &:hover {
        @extend .cursor-pointer;
      }
      &:focus {
        @extend .outline-none;
      }
    }
  }
  