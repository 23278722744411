.card83 {
  @include card-box;
  @extend .position-relative;
  @extend .margin-bottom-20;
  
  .card83-no-header {
    @include custom-min-height(50px);
  }
  
  &-header {
    @extend .padding-12;
    @extend .border-bottom-1;
    @extend .align-items-center;
    @extend .justify-content-between;
    @extend .height-55;
    
    .card83-title {
      @include size-color(16px, $text-color);
      @extend .margin-0;
      @extend .align-items-center;
      // @extend .cursor-pointer;

      span {
        @include size-color(14px, $themeColorPrimary);
        @extend .margin-left-7;
      }
      
      i {
        @extend .flex-center-align;
        @extend .margin-right-10;
      }
    }
    
    h6 {
      @extend .margin-0;
      @include size-color(16px, $text-color);
      @extend .margin-0;
      @extend .align-items-center;

      .button83 {
        @include size-weight-color(16px !important, 600, $text-color);
        i {
          @include size-weight-color(12px !important, 600, $text-color);
        }

        &:hover {
        @extend .text-primary;
        }
      }

      span {
        @include size-color(14px, $themeColorPrimary);
        @extend .margin-left-7;
      }
    }

    .card83-headers-button-group {
      @extend .display-flex;

      .button83 {
        @extend .box-shadow-0;
        @extend .margin-left-10;

        i {
          @extend .f-16;
        }
      }
    }
  }
  
  &-body {
    @extend .padding-12;
    @extend .position-relative;
    
    .card83-chart-wrapper {
      @extend .min-height-350;
    }
    
    &-no-header {
      @extend .min-height-50;
    }
  }
  
  &-footer {
    @extend .padding-12;
    @extend .border-top-1;
    
    h6 {
      @extend .f-14;
      @include color($text-color);
    }
    
    p {
      @include color($text-color);
      @extend .margin-0;
      @extend .align-items-center;
      
      i {
        @extend .f-17;
        @extend .margin-right-15;
      }
      
      span {
        @extend .f-14;
      }
    }
    
    button {
      @extend .margin-left-15;

      &:first-of-type {
        @extend .margin-left-0;
      }
    }
  }
}