.button83 {
  @include button83;
  @extend .transition-3s;

  &:hover {
    @extend .transition-3s;
  }

  /*----------- Primary Buttons83 ------------*/
  &-primary {
    @include button83-primary;
  }

  &-secondary {
    @include button83-secondary;
  }

  &-danger {
    @include button83-danger;
  }

  &-info {
    @include button83-info;
  }

  &-warning {
    @include button83-warning;
  }

  &-dark {
    @include button83-dark;
  }

  &-icon {
    @include button83-icon;
  }

  &-primary-icon {
    @include button83-primary-icon;
  }

  &-secondary-icon {
    @include button83-secondary-icon;
  }

  &-danger-icon {
    @include button83-danger-icon;
  }

  &-info-icon {
    @include button83-info-icon;
  }

  &-warning-icon {
    @include button83-warning-icon;
  }

  &-dark-icon {
    @include button83-dark-icon;
  }

  &-outlined-circle {
    @include button83-outlined-circle;
  }

  &-outlined {
    @include button83-outlined;
  }
  /*----------- End Primary Buttons83 ------------*/

  /*----------- Outlined Buttons83 ------------*/
  &-outlined-default {
    @include button83-outlined-default;
  }

  &-outlined-primary {
    @include button83-outlined-primary;
  }

  &-outlined-secondary {
    @include button83-outlined-secondary;
  }

  &-outlined-info {
    @include button83-outlined-info;
  }

  &-outlined-danger {
    @include button83-outlined-danger;
  }

  &-outlined-warning {
    @include button83-outlined-warning;
  }

  &-outlined-dark {
    @include button83-outlined-dark;
  }

  &-outlined-icon-primary {
    @include button83-outlined-primary-icon;
  }

  &-outlined-icon-secondary {
    @include button83-outlined-secondary-icon;
  }

  &-outlined-icon-info {
    @include button83-outlined-info-icon;
  }

  &-outlined-icon-danger {
    @include button83-outlined-danger-icon;
  }

  &-outlined-icon-warning {
    @include button83-outlined-warning-icon;
  }

  &-outlined-icon-dark {
    @include button83-outlined-dark-icon;
  }
  /*-----------End Outlined Buttons83 ------------*/

  /*-----------Circular Buttons83 ------------*/
  &-circle {
    @include button83-circle;
  }

  &-primary-circle {
    @include button83-primary-circle;
  }

  &-outlined-primary-circle {
    @include button83-outlined-primary-circle;
  }

  &-secondary-circle {
    @include button83-secondary-circle;
  }

  &-outlined-secondary-circle {
    @include button83-outlined-secondary-circle;
  }

  &-warning-circle {
    @include button83-warning-circle;
  }

  &-outlined-warning-circle {
    @include button83-outlined-warning-circle;
  }

  &-info-circle {
    @include button83-info-circle;
  }

  &-outlined-info-circle {
    @include button83-outlined-info-circle;
  }

  &-danger-circle {
    @include button83-danger-circle;
  }

  &-outlined-danger-circle {
    @include button83-outlined-danger-circle;
  }

  &-dark-circle {
    @include button83-dark-circle;
  }

  &-outlined-dark-circle {
    @include button83-outlined-dark-circle;
  }
  /*-----------End Circular Buttons83 ------------*/

  /*----------- default Buttons83 ------------*/
  &-default {
    @include button83-default;
    @extend .border-0;
    @extend .min-width-0;
  }

  &-standard {
    @include button83-standard;
    @extend .border-0;
    @extend .min-width-0;
  }

  &-light {
    @include button83-light;
    @extend .border-0;
    @extend .min-width-0;
  }
  /*-----------End default Buttons83 ------------*/

  /*----------- Default Icon Buttons83 ------------*/
  &-standard-icon {
    @include button83-standard-icon;
    @extend .min-width-0;
    @extend .border-0;
    @extend .box-shadow-0;
  }
  /*-----------End Default Icon Buttons83 ------------*/
}

button[disabled] {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.button-disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.action-buttons-group {
  .button83 {
    @extend .margin-left-10;
    @extend .f-16;
    @include background($icon-buttons-bg);
    @extend .height-35;
    @extend .width-35;
    @extend .border-radius-5;

    &:first-of-type {
      @extend .margin-left-0;
    }

    &.button83-darkBlue {
      @include color($themeColorPrimary);
      @include background((#33a0c71f));

      &:hover {
        @include background($themeColorPrimary);
        @include color($white);
      }
    }

    &.button83-green {
      @include background(#05af3e2e);
      @include color($button83-green);

      &:hover {
        @include background($button83-green);
        @include color($white);
      }
    }

    &.button83-danger {
      @include color($danger-color);
      @include background(#f07e261a);

      &:hover {
        @include background($danger-color);
        @include color($white);
      }
    }

    &.button83-lightBlue {
      @include color($button83-lightBlue);
      @include background(#66b9f12e);

      &:hover {
        @include background($button83-lightBlue);
        @include color($white);
      }
    }

    &.button83-blue {
      @include color($button83-blue);
      @include background(#1492e614);

      &:hover {
        @include background($button83-blue);
        @include color($white);
      }
    }

    &.button83-dark {
      @include color($button83-dark);
      @include background(#04050517);

      &:hover {
        @include background($button83-dark);
        @include color($white);
      }
    }

    &.button83-purple {
      @include color($button83-purple);
      @include background(#7d307d1a);

      &:hover {
        @include background($button83-purple);
        @include color($white);
      }
    }

    &.button83-lightYellow {
      @include color($button83-lightYellow);
      @include background(#fdd9171a);

      &:hover {
        @include background($button83-lightYellow);
        @include color($white);
      }
    }

    &.button83-yellow {
      @include color($button83-yellow);
      @include background(#dac5051a);

      &:hover {
        @include background($button83-yellow);
        @include color($white);
      }
    }

    &.button83-darkYellow {
      @include color($button83-darkYellow);
      @include background(#a2a86e29);

      &:hover {
        @include background($button83-darkYellow);
        @include color($white);
      }
    }
  }
}

.buttons83-group {
  @extend .align-items-center;
  @extend .justify-content-end;
  @extend .margin-x-10;

  button {
    @extend .min-width-70;

    &:first-of-type {
      @extend .margin-right-10;
    }
  }
}
