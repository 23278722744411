/************************************************************************
* 83incs CONFIDENTIAL
* ***********************************************************************
*
*  [2017] - [2022] 83incs Ltd.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of 83incs Ltd, IoT83 Ltd, its suppliers (if any), its subsidiaries (if any) and
* Source Code Licenses (if any).  The intellectual and technical concepts contained
* herein are proprietary to 83incs Ltd, IoT83 Ltd, its subsidiaries (if any) and
* Source Code Licenses (if any) and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from 83incs Ltd or IoT83 Ltd.
****************************************************************************
*/

/**
*
* Flex
*
*/

/*----- Display flex classes For all Browsers e.g @extend display-flex -----*/
.display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.d-flex-row {
  @extend .display-flex;
  @include margin(0px, -12px, 0px, -12px);
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-center-align {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
}

.flex-right-align {
  @extend .display-flex;
  @extend .align-items-center;
  @extend .justify-content-end;
}

.align-items-center {
  @extend .display-flex;
  align-items: center;
}

.align-items-end {
  @extend .display-flex;
  align-items: end;
}

.align-items-start {
  @extend .display-flex;
  align-items: start;
}

.align-items-center-right {
  @extend .display-flex;
  @extend .align-items-center;
  @extend .justify-content-end;
}

.flex-row {
  @extend .display-flex;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-row-reverse {
  @extend .display-flex;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column {
  @extend .display-flex;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.align-self-center {
  align-self: center;
}

.justify-content-start {
  @extend .display-flex;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  @extend .display-flex;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  @extend .display-flex;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  @extend .display-flex;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  @extend .display-flex;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-7_5 {
  flex-basis: 7.5% !important;
  max-width: 7.5% !important;
}

.flex-8_5 {
  flex-basis: 8.5% !important;
  max-width: 8.5% !important;
}

.flex-12_5 {
  flex-basis: 12.5% !important;
  max-width: 12.5% !important;
}

.flex-16_6 {
  flex-basis: 16.65% !important;
  max-width: 16.65% !important;
}

.flex-17_5 {
  flex-basis: 17.5% !important;
  max-width: 17.5% !important;
}
.flex-18_3 {
  flex-basis: 18.33% !important;
  max-width: 18.33% !important;
}

.flex-21_5 {
  flex-basis: 21.5% !important;
  max-width: 21.5% !important;
}

.flex-22_5 {
  flex-basis: 22.5% !important;
  max-width: 22.5% !important;
}

.flex-23_5 {
  flex-basis: 23.5% !important;
  max-width: 23.5% !important;
}

.flex-27_5 {
  flex-basis: 27.5% !important;
  max-width: 27.5% !important;
}

.flex-33 {
  flex-basis: 33.3333% !important;
  max-width: 33.3333% !important;
}

.flex-67 {
  flex-basis: 66.6667% !important;
  max-width: 66.6667% !important;
}

.flex-37_5 {
  flex-basis: 37.5% !important;
  max-width: 37.5% !important;
}

.flex-42_5 {
  flex-basis: 42.5% !important;
  max-width: 42.5% !important;
}

.flex-82_5 {
  flex-basis: 82.5% !important;
  max-width: 82.5% !important;
}

.flex-87_5 {
  flex-basis: 87.5% !important;
  max-width: 87.5% !important;
}
/*------ End-------*/
