// modal-xl for full width and modal-md for medium width

.modal-wrapper {
  @extend .position-fixed;
  @include custom-left-top(0, 0);
  @include custom-z-index(9999);
  @include custom-height-width(100%, 100%);
  @extend .overflow-x-hidden;
  @extend .overflow-y-auto;
  @extend .outline-none;
  @include background(rgba(0, 0, 0, 0.5));
  @extend .overflow-y-hidden;

  .modal-dialog {
    @extend .position-relative;
    @extend .width-auto;
    @extend .flex-center-align;
    @include custom-min-height(calc(100% - 56px));
    @include margin(28px, auto, 28px, auto);

    &.modal-sm {
      @include custom-max-width(560px !important);
    }

    &.modal-md {
      @include custom-max-width(800px !important);
    }

    &.modal-lg {
      @include custom-max-width(calc(100% - 500px) !important);
    }

    &.modal-xl {
      @include custom-max-width(calc(100% - 300px) !important);
    }

    .modal-content {
      @include card-box;
      @extend .w-100;
      @extend .border-radius-16;

      .modal-header {
        @extend .justify-content-between;
        @extend .padding-10;
        @include background($white);
        @include custom-border-radius(8px, 8px, 0, 0);
        @include border(border-bottom, 1px solid $border-color);

        h5 {
          @include margin(5px, 0px, 5px, 0px);
          @include size-weight-color(15px, 600, $text-color);
        }

        .button83-icon {
          @include background-and-textColor(#e64f4f14, $danger-color !important);
          @extend .f-16;
          @extend .border-0;
          @include custom-height-width(32px, 32px);
          @extend .border-radius-8;
        }
      }

      .modal-scrollable {
        @include custom-max-height(calc(100vh - 225px));
        @extend .overflow-y-auto;
        @extend .overflow-x-hidden;
        @extend .min-height-130;
      }

      .modal-header-delete {
        // background: linear-gradient(-45deg, $red 31%, $toggle-normal-red) !important;

        h5 {
          @extend .fw-800;
        }
      }

      .modal-body {
        @extend .padding-12;

        tr {
          @extend .border-radius-16;
          &:last-of-type {
            td {
              &:first-child {
                @include custom-border-radius(0px, 0px, 0px, 8px);
              }

              &:last-child {
                @include custom-border-radius(0px, 0px, 8px, 0px);
              }
            }
          }
        }
      }

      .form-group input[type="color"] {
        @include custom-height(42px);
      }

      .modal-body-sm {
        @include height-overflow(calc(100vh - 520px));
      }

      .modal-body-xl {
        @include height-overflow(calc(100vh - 220px));
      }

      .delete-modal {
        @include height-overflow(252px);

        .delete-modal-content {
          @extend .w-100;
          @extend .flex-center-align;

          .delete-modal-img {
            @include custom-height-width(150px, 120px);

            img {
              @include image;
            }
          }
        }

        .delete-modal-text {
          @extend .w-100;

          p {
            @include color($text-color);
            @extend .f-17;
            @extend .text-center;

            span {
              @include color($red);
              @extend .fw-700;
            }
          }
        }

        .form83-group {
          @extend .width-180;
          @include margin(15px, auto, 0px, auto);
        }
      }

      .modal-footer {
        @extend .padding-10;
        @extend .justify-content-end;
        @extend .border-top-1;

        button {
          @extend .text-center;
          @include size-weight(14px, 400);
          @extend .min-width-70;

          i {
            @extend .margin-right-0;
          }
        }
      }
    }
  }

  &.sliding-modal83 {
    .modal-body {
      @extend .h-100;
    }

    .modal-dialog {
      @include custom-max-width(500px);
      @include margin(30px, auto, 30px, auto);
      animation: moveFromCenterToTop 0.6s ease both;

      .modal-content {
        @include custom-border-radius(8px, 8px, 8px, 8px);

        .modal-footer {
          @include custom-border-radius(0px, 0px, 0px, 8px);
        }
      }
    }
  }

  &.sliding-modal83-transition {
    .modal-dialog {
      animation: moveFromTop 0.6s ease both;
    }
  }

  form {
    @include background-transparent;
    @extend .box-shadow-0;

    .form83-scrollable-body-lg {
      @include custom-height(auto);
      @include padding(0px, 0px, 0px, 0px);
    }
  }
}

.modal-close {
  @include custom-position-top-right(-10px, -10px);
  @include padding(0.3em, 0.3em, 0.3em, 0.3em);
  @extend .cursor-pointer;
  @include size-weight(2em, 600);
  @include custom-height-width(1em, 1em);
  @include custom-text-indent(10em);
  @extend .overflow-hidden;
  @extend .border-0;
  @include background-and-textColor(#dff2fb, $themeColorPrimary);
  @extend .border-radius-8;

  &:after {
    content: "\00D7";
    @include custom-line-height(0.5);
    @include custom-position-top-left(7px, 7px);
    @include custom-text-indent(0em);
    @extend .f-23;
  }
}

@media only screen and (max-width: 600px) {
  .modal-xl,
  .modal-lg,
  .modal-md {
    @include custom-padding-left(70px);
    @include custom-max-width(calc(100% - 20px) !important);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px; /* Adjust the width as desired */
  height: 100px; /* Adjust the height as desired */
}

.spinner-container .fa-spinner {
  margin-right: -290px !important;
  font-size: 18px; /* Adjust the font size as desired */
  color: #000; /* Adjust the color as desired */
}
