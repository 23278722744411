.dropdown83 {
  @extend .position-relative;
  
  .dropdown-menu83 {
    @include custom-position-top-right(30px, 3px);
    @include background-white;
    @extend .min-width-130;
    @extend .min-height-60;
    @extend .padding-12;
    @include custom-z-index(99 !important);
    @extend .border-radius-8;
    @include custom-box-shadow(#00000014 3.5px 4px 30px);
    @extend .align-items-center;
    // @extend .justify-content-center;
    @extend .flex-column;
    
    .dropdown-menu-list {
      @extend .flex-column;
      @extend .cursor-pointer;
      gap: 1rem;
      @extend .f-16;
      @extend .justify-content-center;
      @extend .align-items-center;
      @extend .padding-20;
      
      & > li {
        @extend .w-100;
        @extend .justify-content-around;
        @extend .align-items-center;
      }
    }
    
    .dropdown-item83 {
      @extend .w-100;
      
      button {
        @extend .w-100;
        @extend .f-13;
        @extend .padding-y-7;
        @extend .justify-content-start;

        i {
          @extend .f-13;
          @include custom-margin-right(12px !important);
          
          &.fa-eye {
            @extend .text-secondary;
          }

          &.fa-pencil {
            @extend .text-primary;
          }

          &.fa-trash {
            @extend .text-danger;
          }
        }
      }
    }
  }
}

.dropdown83-show {
  &::after {
    content: "";
    @include custom-height-width(0px, 0px);
    @include border(border-left, 7px solid transparent);
    @include border(border-right, 7px solid transparent);
    @include border(border-bottom, 7px solid #EBF0F5);
    @include custom-position-bottom-right(-9px, 4px);
  }
}
