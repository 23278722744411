.status83-wrapper {
  .status-badge {
    @extend .display-flex;
    @extend .f-14;
    @extend .fw-400;
    @extend .cursor-pointer;
    @extend .align-items-center;

    &-icon {
      @extend .position-relative;
      @include custom-height-width(19px, 19px);
      @include margin(0, 7px, 0, 0);
      @extend .border-2;

      i {
        @include size-weight(12px, 500);
        @extend .position-absolute;
        @include transform-center-align;
      }
    }

    .rounded {
      @extend .border-radius-5;

      i {
        @extend .opacity-0;
      }
    }

    // &.status-badge-active {

    //   .rounded {
    //     @extend .bg-success;

    //     i {
    //       @extend .opacity-0;
    //     }
    //   }

    //   .status-badge-icon {
    //     @include custom-border-color($button83-green !important);
    //   }
    // }

    // &.status-badge-Inactive {
    //   .rounded {
    //     @extend .bg-danger;

    //     i {
    //       @extend .opacity-0;
    //     }
    //   }

    //   .status-badge-icon {
    //     @include custom-border-color($toggle-normal-red !important);
    //   }
    // }

    &.status-badge-active {
      @include color($button83-green);

      .status-badge-icon {
        @include custom-border-color($button83-green !important);
      }
    }

    &.status-badge-primary {
      @include color($button83-blue);

      .status-badge-icon {
        @include custom-border-color($button83-blue !important);
      }
    }

    &.status-badge-warning {
      @include color($button83-yellow);

      .status-badge-icon {
        @include custom-border-color($button83-yellow !important);
      }
    }

    &.status-badge-Inactive {
      @extend .text-danger;

      .status-badge-icon {
        @include custom-border-color($toggle-normal-red !important);

        i {
          @include size-weight(13px, 300);
          @include transform-center-align;
          @include color($toggle-normal-red);
        }
      }
    }
  }

  .status {
    &-text {
      @extend .margin-0;
      @extend .padding-7;
      @include size-weight(13px, 600 !important);
      @extend .border-radius-8;
      @extend .max-width-100;
      @extend .text-center;
      @extend .width-auto;
      @extend .align-items-center;
      @extend .justify-content-center;
      @extend .position-relative;

      &:before {
        @include custom-height-width(12px, 12px);
        @include custom-left(10px);
        @include transform-top-center-align;
        @include outline-offset(3px);
      }
    }

    &-online {
      @include background-and-textColor($transparent, #05af3e !important);

      &:before {
        content: "";
        @include background(#05af3e);
        @include outline(outline, 1px solid #05af3e75);
      }
    }

    &-offline {
      @include background-and-textColor(
        $transparent,
        $toggle-normal-red !important
      );

      &:before {
        content: "";
        @include background($toggle-normal-red);
        @include outline(outline, 1px solid #c3223175);
      }
    }

    &-pending {
      @include background-and-textColor($transparent, #e5ac02 !important);

      &:before {
        content: "";
        @include background(#e5ac02);
        @include outline(outline, 1px solid #e5ac0275);
      }
    }

    &-info {
      @include background-and-textColor($transparent, #1c67b9 !important);

      &:before {
        content: "";
        @include background(#1c67b9);
        @include outline(outline, 1px solid #1c67b975);
      }
    }
  }
}
