.no-data-found83 {
    @extend .height-300;

    &-container {
        @extend .flex-column;
        @extend .flex-center-align;
        @extend .text-center;
        @include horizontal-vertical-center(center);

        p {
            @include size-weight-color(14px, 600 !important, $text-color !important);
        }

        h6 {
            @extend .margin-top-0;
            @extend .margin-bottom-0;
            @include size-weight-color(14px !important, 600 !important, $themeColorSecondary !important);
        }
    }

    &-image {
        @extend .margin-bottom-15;

        img {
            @extend .width-65;
        }
    }
}