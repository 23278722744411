.nested-card83 {
  @extend .padding-12;
  @extend .border-radius-8;

  .nested-card83-header {
    h6 {
      @include size-weight(13px, 600);
      @extend .margin-bottom-20;
      @extend .margin-top-0;
    }
  }

  .nested-card83-body {
    @include background(#f7f8f9 !important);
    @extend .padding-12;
    @extend .border-radius-8;

    h6 {
      @include size-weight(13px, 600);
      @extend .margin-bottom-7;
      @extend .margin-top-0;
    }

    p {
      @include size-weight(13px, 600);
    }
  }
}

.keys-list {
  @extend .list-style-none;
  @extend .padding-12;

  li {
    h6 {
      @include size-weight(13px, 600);
      @extend .margin-bottom-7;
      @extend .margin-top-0;
    }

    p {
      @include size-weight(13px, 600);
    }

    button {
      @include size-weight(13px !important, 600);
      @extend .padding-0;

      &:hover {
        @extend .text-underline;
      }

      i {
        @extend .f-16;
      }

      &.text-primary {
        @include color($themeColorPrimary!important);
      }

      &.text-danger {
        @include color($danger-color !important);
      }
    }
  }
}

.hide-header-card83 {
  .card83-header {
    .card83-title {
      opacity: 0 !important;
      @extend .display-none;
    }

    button {
      @extend .fw-700;
    }
  }
}

.custom-react-box {
  @extend .width-170;
}

.custom-overflow {
  .nested-card83 {
    @include padding(0px, 0px, 0px, 0px);

    .nested-card83-body {
      p {
        @include size-weight(12px, 500);
      }
    }
  }

  .keys-list {
    @extend .margin-top-20;
    @include padding(0px, 0px, 0px, 0px);
  }
}

.editModal {
  .form83 {
    .form83-scrollable-body-lg {
      @include custom-margin-bottom(0px !important);
    }
  }
}

.logout83 {
  @extend .padding-0;
  @extend .margin-0;

  i {
    @include custom-margin-right(0px !important);
  }

  &:hover {
    @include color($danger-color !important);
  }
}

.subscription-card-card83 {
  .custom-select-button {
    margin-bottom: 30px;

    .form83-group {
      margin-bottom: 0px !important;
      margin-right: 10px;
    }
  }
}