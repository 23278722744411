.table83-wrapper {
  @extend .border-radius-8;
  @include card-box;
  padding: 0px !important;

  .table83 {
    border-collapse: separate;
    @extend .border-0;
    border-spacing: 0 0px;
    table-layout: fixed;
    @extend .w-100;
    @extend .border-radius-0;
    @include custom-border-radius(8px, 8px, 8px, 8px);
    height: 100%;

    table {
      @extend .w-100;
    }

    .table83-header {
      @include padding(12px, 12px, 12px, 12px);
      @extend .border-bottom-1;
      @extend .align-items-center;
      @extend .justify-content-between;
      @extend .height-60;
      @extend.position-relative;

      .table83-title {
        @include size-color(16px, $text-color);
        @extend .margin-0;
        @extend .align-items-center;

        span {
          @include size-color(14px, $themeColorPrimary);
          @extend .margin-left-7;
        }

        i {
          @extend .flex-center-align;
          @extend .margin-right-10;
        }
      }

      h6 {
        @extend .margin-0;
        @include size-color(16px, $text-color);
        @extend .margin-0;
        @extend .align-items-center;

        span {
          @include size-color(14px, $themeColorPrimary);
          @extend .margin-left-7;
        }
      }

      .table83-header-buttons-group {
        @extend .display-flex;

        .button83 {
          @extend .box-shadow-0;
          @extend .margin-left-10;
          @include background-and-textColor(#7a91992b, #7a9199);

          i {
            @extend .f-16;
          }
        }
      }
    }

    tr {
      @extend .border-radius-0;

      &:last-of-type {
        td {
          @include border(border-bottom, 0px solid $border-color !important);
        }
      }
    }

    thead {
      @extend .border-radius-0;
      @include background-white;
      @extend .margin-bottom-0;
      @extend .position-sticky;
      z-index: 1;

      tr {
        @extend .padding-bottom-0;
      }

      th {
        @include size-weight-color(13px, 700, $content-color-dark);
        @include padding(15px, 10px, 15px, 10px);
        @extend .border-0;
        @extend .vertical-align-top;
        @extend .text-truncate;
        text-align: start;
        width: 200px !important;
        @include border(border-bottom, 1px solid $border-color !important);

        &:first-child {
          @include custom-border-radius(0px, 0, 0, 0px);
          @include border(border-left, 0px solid $border-color !important);
          @include custom-border-radius(0px, 0px, 0px, 0px);
        }

        &:last-of-type {
          @include custom-border-radius(0px, 0px, 0px, 0px);
          @include border(border-right, 0px solid $border-color !important);
          @extend .text-right;
        }

        input,
        select {
          @extend .padding-12;
          @extend .margin-top-5;
          @extend .width-100;
          @include size-color(13px, $text-color);
          @include custom-line-height(normal);
          @extend .w-100;
          @extend .border-radius-4;
          @include border(border, 1px solid $border-color);
          @include transition-5s;

          &:hover {
            @include border(border, 1px solid $border-color-dark);
            @include transition-5s;
          }
        }
      }

      .tabs83-tabs-list {
        .tabs83-tab-items {
          @extend .padding-x-10;
          @extend .cursor-pointer;
          @extend .padding-bottom-7;
          @extend .border-0;
          @extend .box-shadow-0;

          &:first-of-type {
            @extend .padding-left-0;
          }

          &.active {
            @include border(
              border-bottom,
              1px solid $themeColorPrimary !important
            );
          }
        }
      }
    }

    tbody {
      tr {
        @include transition-3s;
        @extend .cursor-pointer;
      }

      tr:hover {
        @include custom-z-index(1);
        @include transition-3s;
        @include custom-box-shadow(0px 3px 11px -9px rgb(0 0 0 / 40%));
      }

      td {
        @include padding(12px, 10px, 12px, 10px);
        @extend .box-shadow-0;
        @extend .border-0;
        @include word-wrap(break-word);
        @include custom-border-radius(0px, 0px, 0px, 0px);
        @extend .f-13;
        @include border(border-bottom, 1px solid $border-color !important);
        @include custom-width(300px);

        &:last-child {
          @include border(border-right, 0px solid $border-color !important);
          @extend .text-right;
        }

        &::before {
          @include content(1px, 70%, $border-color);
          @include custom-position-top-center-left(0);
        }

        &:first-of-type {
          &::before {
            @extend .display-none;
          }
        }

        a {
          @extend .text-secondary;

          &:hover {
            @extend .text-underline;
          }
        }

        .button83 {
          @include custom-min-width(28px !important);
          @include custom-min-height(28px !important);
          @include outline(outline, 1px solid $border-color);
          @include outline-offset(4px);
          @extend .margin-left-15;
          @extend .border-0;
          @extend .align-items-center;
          @extend .justify-content-center;
          @include custom-height-width(28px !important, 28px !important);

          i {
            @extend .f-14;
            @include custom-margin-right(0px !important);
          }
        }

        h6 {
          @include size-weight-color(13px, 600, $text-color);
          @include text-truncate;
          @extend .margin-top-0;
          @extend .margin-bottom-5;
        }

        p {
          @include size-weight-color(12px, 400, $content-color);
          @include text-truncate;
        }

        .btn-outline-light {
          @extend .padding-0;
          @include circle(30px, 30px);
        }

        .no-data-found83 {
          @include height-overflow(calc(100vh - 256px));
        }
      }
    }
  }

  .button-group {
    .button83 {
      @extend .margin-left-15;
      @extend .f-16;
      @include background($icon-buttons-bg);
      @extend .height-35;
      @extend .width-35;

      &:first-of-type {
        @extend .margin-left-0;
      }

      &.button83-eye {
        @extend .text-primary;
        @include border(border, 1px solid $themeColorPrimary !important);

        &:hover {
          @include background($card-light-blue-bg);
        }
      }

      &.button83-edit {
        @include color($succes-button83);
        @include border(border, 1px solid $succes-button83 !important);

        &:hover {
          @include background(#05af3e2e);
        }
      }

      &.button83-trash {
        @include color($danger-button83);
        @include border(border, 1px solid $danger-button83 !important);

        &:hover {
          background-color: #f07e261a;
        }
      }
    }
  }

  .search83.form83-group {
    @include custom-margin-right(0px !important);
  }
}

.table83-full {
  @include background-white;

  tbody {
    @extend .h-100;
  }
}

.pagination {
  @include padding(12px, 6px, 12px, 6px);
  @include background-white;
  @extend .align-items-center;
  @include custom-border-radius(0px, 0px, 8px, 8px);
  @extend .position-sticky;
  @include custom-left-bottom(0px, 0px);
  @extend .border-top-0;
  @extend .w-100;
  @extend .border-top-1;

  select,
  input {
    @include padding(3px, 2px, 3px, 12px);
    @include size-color(12px, $text-color);
    @extend .border-1;
    @extend .cursor-pointer;
    @include custom-height-width(35px, 60px !important);
  }

  span {
    @extend .display-inline-block;
    @include content-color;
    @include margin(0px, 8px, 0px, 8px);
    @extend .f-12;

    strong {
      @include dark-theme-color;
      @extend .fw-500;
    }
  }

  .pagination {
    @extend .margin-0;

    .page-item {
      &.active {
        .page-link {
          @include background-theme;
          @include custom-border-color($themeColorPrimary);
          @extend .text-primary;
          @extend .cursor-default;
        }
      }
    }
  }

  .button83-default {
    @extend .margin-right-7;
    @include custom-height-width(35px, 35px);

    i {
      @extend .margin-right-0;
    }
  }
}

.table-sm {
  tr {
    &:last-of-type {
      td {
        @include custom-border-radius(0px, 0px, 0px, 0px);

        &:first-child {
          @include custom-border-radius(0px, 0px, 0px, 8px);
        }

        &:last-child {
          @include custom-border-radius(0px, 0px, 8px, 0px);
        }
      }
    }
  }
}

.table-no-header {
  .table83-wrapper table thead {
    @extend .display-none;
  }
}

.table-view-imgs {
  .img-wrapper {
    @include image-wrapper(40px, 40px);
    @include border-radius-50;
    @extend .margin-right-10;
  }
}

.table83-parent {
  @include custom-height(calc(100vh - 295px));
  @extend .padding-top-0;
  @extend .overflow-y-auto;
  @extend .position-relative;
  @extend .border-radius-7;
  @include background-white;
  @extend .border-radius-8;
  @include padding(12px, 12px, 12px, 12px);
  @include transition-3s;
  @include custom-border-radius(8px, 8px, 0px, 0px);

  .no-data83-wrapper {
    @include custom-height(calc(100vh - 373px));

    .no-data-found83 {
      @include custom-height(auto);
    }
  }

  thead {
    @include custom-top(-13px !important);
  }

  .loader83-container {
    @include custom-height(calc(100vh - 430px) !important);
  }
} 

.table-bordered {
    td,th {
        border: 1px solid $border-color;
        border-radius: 5px;
        .action-buttons-group {
            .button83 {
                outline: 1px solid $border-color;
                outline-offset: 4px;
                height: 25px !important;
                width: 25px !important;
                min-height: 25px;
                min-width: 25px;
                border: 0;
                i {
                    margin-right: 0
                }
            }
        }
    }
    th {
        padding: 8px 10px;
    }
    td {
        padding: 5px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        h6 {
            @include size-color(13px,$content-color);
            margin: 0;
        }
    }
}