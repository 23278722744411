.login-img {
  @extend .bg-white;
  @extend .h-100vh;
  @extend .align-items-center;
  @include custom-padding-left(100px);

  > img {
    @extend .position-fixed;
    right: 11%;
    @extend .bg-white;
    @extend .border-radius-20;
    @include custom-box-shadow(-4px 0px 34px 0px rgba(0,0,0,0.1));
    @extend .padding-30;
    @include custom-width(550px);
    box-sizing: border-box;
  }
}

.login-info {
  @include background($themeColorSecondary);
  @include custom-border-radius(0px, 0px, 0px, 450px);
  @extend .h-100vh;
}

.login-taglines {
  @include custom-max-width(500px);

  .logo {
    @include custom-height-width(45px, auto);
    @extend .margin-left-20;
  }

  h1 {
    @include margin(0px, 0px, 0px, 0px);
    @extend .text-center;
    @include size-weight-color(50px, 400, $themeColorSecondary);
  }

  h6 {
    @include size-weight-color(14px, 400, $text-color);
    @include margin(15px, 0px, 50px, 0px);
    @extend .text-center;
    @include custom-letter-spacing(1.4px);
  }

  p {
    @extend .text-dark;
    @extend .margin-y-15;
  }

  button {
    @include custom-min-height(45px);
    @include custom-min-width(250px);
  }
}