.loader83-container {
    @extend .min-height-150;
    @extend .h-100;
    @extend .position-relative;

    .loader83 {
        @include transform-center-align;
        .horizontal-line {
            @include custom-box-shadow(0px 3px #0000001c);
            animation: floatfast 2s linear infinite;
            @extend .padding-10;
            @extend .position-relative;
            @include custom-top(-7px);
        }

        .fullscreen-loader {
            img {
                @extend .width-70;
                @include custom-position-top-left(40px, 7px);
            }
        }

        .fullscreen-inner-loader {
            @include custom-height-width(90px, 90px);
            @extend .display-inline-block;
            @extend .position-relative;
            @include color($themeColorPrimary);
            animation: rotation 2s linear infinite;

            &:after {
                content: "";
                @include custom-height-width(35px, 35px);
                @include custom-position-top-left(35px, 65px);
                @include background($themeColorSecondary);
                @include border-radius-50;
                animation: scale50 1s infinite ease-in-out;
                @include transform-translate(-50%, 35px);
            }

            &:before {
                content: "";
                @extend .position-absolute;
                @include custom-height-width(35px, 35px);
                @include border-radius-50;
                animation: scale50 1s infinite ease-in-out;
                @include custom-top(auto);
                @include custom-bottom(-11px);
                @include background($themeColorPrimary);
                animation-delay: 0.5s;
            }
        }

        .black-circle {
            @extend .display-inline-block;
            @include custom-height-width(90px, 90px);
            @extend .position-relative;

            &:before {
                content: "";
                @include custom-position-top-bottom(0px, 0px);
                @include custom-height-width(35px, 35px);
                @include border-radius-50;
                animation: scale50 1s infinite ease-in-out;
                @include background($content-color-dark);
                animation-delay: 0.5s;
            }
        }

        .small-loader-wrapper {
            @extend .position-relative;

            img {
                @include custom-width(26px);
                @include custom-position-top-left(8px, 5px);
                animation: zoom-in-zoom-out 2s ease-out infinite;
            }

            .small-loader {
                @extend .position-relative;
                @include custom-height-width(40px, 40px);
                @include border-radius-50;
                @extend .display-inline-block;
                @include border(border-top, 4px solid $themeColorPrimary);
                @include border(border-right, 4px solid transparent);
                animation: rotation 1s linear infinite;
                @extend .text-left;

                &:after {
                    content: "";
                    @include position-top-bottom;
                    @include custom-height-width(40px, 40px);
                    @include border-radius-50;
                    @include border(border-left, 4px solid $themeColorSecondary);
                    @include border(border-bottom, 4px solid transparent);
                    animation: rotation 0.5s linear infinite reverse;
                    @extend .text-left;
                }
            }
        }
    }
}