.header83 {
  @include card-box;
  @extend .position-sticky;
  @extend .padding-y-12;
  @extend .padding-x-24;
  @include custom-z-index(9);
  @extend .border-bottom-1;

  .logo {
    img {
      @include image-wrapper-custom;
      @extend .box-shadow-0;
      @include custom-height-width(50px, 100px !important);
    }
  }

  nav {
    > ul {
      @extend .align-items-center;
      @extend .justify-content-center;

      > li {
        @extend .position-relative;
        @extend .padding-x-20;

        a {
          @include size-weight-color(14px, 600, $text-color);
          @extend .text-decoration-none;

          &:hover {
            @extend .text-primary;
          }

          i {
            @extend .margin-left-2;
            @include size-weight(11px, 600);
          }
        }

        ul {
          @include custom-left-top(-25px, 25px);
          @extend .min-width-160;
          @include background-white;
          @include padding(15px, 12px, 15px, 12px);
          @extend .position-absolute;
          @extend .border-radius-8;
          @extend .opacity-0;
          @extend .visibility-hidden;
          @include custom-box-shadow(0 0 25px 0 #0000001a);
          -webkit-transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
          transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
          -webkit-transform: translate3d(-15px, 15px, 0);
          -moz-transform: translate3d(-15px, 15px, 0);
          -o-transform: translate3d(-15px, 15px, 0);
          -ms-transform: translate3d(-15px, 15px, 0);
          transform: translate3d(-15px, 15px, 0);

          li {
            @extend .padding-bottom-10;

            &:last-of-type {
              @extend .padding-bottom-0;
            }
          }
        }
      }
    }
  }

  .disabled-item {
    color: #999999;
    cursor: not-allowed;
    /* Add any other styling to indicate the item is disabled */
  }

  nav ul li:hover ul {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    @extend .opacity-1;
    @extend .visibility-visible;
  }
}