// base colors
$themeColorPrimary: #4aa24c;
$themeColorSecondary: #2E75B6;
$themeColorGreen: #50B052;
$text-color: #3C434D;
$content-color: #3C434D;
$content-color-light: #4C6D80;
$content-color-dark :#1D2D3F;
$light-border-color: #F2F6FA;
$input-bg: #F2F6FA;
$border-color: #E1E4E9;
$border-color-dark: #959FAE;
$content-wrapper-bg: #EBF0F5;
$light-color: #ededed;
$light-bg: #efefef;
$btn-light: #f8f9fa;

$white: #ffffff;
$header-bg: $white;
$card-light-blue-bg: #f1f7fb;
$warning-color: #ffc107;
$danger-color: #E64F4F;
$accordian-bg: #f1f7fb;
$light-blue-bg: #dfeef8;
$light-primary-bg: #0864a81f;
$light-yellow-bg: #ffc2001c;
$light-success-bg: rgba(80, 176, 82, 0.1);
$light-danger-bg: #f07e2626;
$text-gray: #afafaf;
$light-content-color: #959FAE;
$light-gray-bg: #F3F6FA;
$text-dark-gray: #808080;

// custom colors
$black: #000000;
$red: #D94C56;
$green: #00b300;
$orange: #ffa500;
$yellow: #ffd700;
$indigo: #8171d9;
$pink: #e83e8c;
$blue: #0367fc;
$info: #0dcaf0;
$success: #50B052;

$light-theme-bg: rgba(51, 160, 199, 0.1);
$light-red-bg: #FEEFEF;
$light-green-bg: #E4F6EA;

$light-yellow-bg: #FFF8E4;
$light-info-bg: #b7eefa;

$light-blue-color: #d5e5f1;
$disabled-bg-color: #e1e1e1;
$disabled-color: #8d8d8d;
$transparent: transparent;
$toggle-normal-green: #00ae3a;
$toggle-normal-light-green: #ddf5e1;
$toggle-normal-red: #c32231;
$toggle-normal-light-red: #dc35451c;

$toggle-red-color: #ff1f75;
$toggle-green-color: #00fa98;

$succes-button83: #05AF3E;
$danger-button83: #F07E26;
$icon-buttons-bg: #FAFAFA;

// Action Buttons Colors
$button83-green: #05af3e;
$button83-danger:#f07e26;
$button83-lightBlue: #66b9f1;
$button83-blue: #1492e6;
$button83-dark: #040505;
$button83-purple: #7d307d;
$button83-lightYellow: #fdd917;
$button83-yellow: #dac505;
$button83-darkYellow: #a2a86e;

/* Prefixes_values */
$known-prefixes: webkit, moz, ms, o;

/* rounded-corners_value */
$rounded-corners: true;

/* Directions_values */
$all-directions : top,
right,
bottom,
left;

/* Display_values */
$displays: block, none, inline-block, inline-flex, inline, grid;

/* Display_values */
$cursors: pointer, default, not-allowed, none, all-scroll;

/* Object-fit_values */
$object-fit: contain, cover, fill, none, inherit, initial, scale-down, unset, revert;

/* vertical-align_values */
$vertical-align: top, bottom, middle;

/*---- Weights ---*/
$weights: 100, 200, 300, 400, 500, 600, 700, 800, 900, normal, bold, bolder, lighter;

$text-classes : 'primary', 'secondary', 'danger', 'success', 'warning', 'info', 'dark', 'gray';

/* ----- padding Values --------- */
$digit_values: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;