.breadCrumbs83 {
  @include padding(24px, 24px, 0px, 24px);

  ul {
    @extend .align-items-center;
    @extend .justify-content-start;

    li {
      @extend .padding-right-10;
      @extend .position-relative;

      i {
        @include size-color(13px, $themeColorSecondary);
        @extend .margin-left-10;
      }

      &:last-of-type {
        i {
          @extend .display-none;
        }
      }

      &:last-of-type, &.active {
        @extend .padding-right-0;

        &:after {
          @extend .display-none;
        }
      }

      &.active {
        @extend .text-secondary;
        @extend .fw-600;
      }
    }
  }
  .form83-group {
    margin-bottom: 0;
    margin-right: 10px;
    .form83-control-multi-select {
        height: 40px;
        min-width: 180px;
    }
    .form83-control-multi-select > div {
        height: 100%;
        padding: 0 !important;
        // outline: 0;
    }
  }
}
