@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://pro.fontawesome.com/releases/v5.13.0/css/all.css");

html,
body {
  @extend .margin-0;
  @extend .padding-0;
  @extend .f-14;
  @extend .fw-400;
  font-family: "Nunito Sans", sans-serif !important;
  @include custom-letter-spacing(0.2px);
  @include background($content-wrapper-bg);
  @extend .min-h-100vh;
  // &::-webkit-scrollbar {
  //   @extend .display-none;
  // }
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

p,
label,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
input,
strong,
button {
  font-family: "Nunito Sans", sans-serif !important;
  @include custom-line-height(normal);
}

h1, h2, h3, h4, h5, h6 {
  @extend .margin-bottom-7;
  @extend .margin-top-7;
  @include size-weight(14px, 600);
}

p {
  @extend .margin-0;
  @extend .fw-500;
}

strong {
  @extend .fw-800;
}

button {
  @extend .cursor-pointer;
}

input,
input:hover,
input:focus,
textarea,
textarea:hover,
textarea:focus,
button:focus,
select,
select:hover,
select:focus {
  @extend .outline-none;
  @extend .box-shadow-0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  @extend .box-shadow-0;
  @extend .bg-transparent;
}

.list-style-none {
  list-style: none;
  @extend .margin-0;
  @extend .padding-0;
}

.outline-none {
  outline: none !important;
}

.box-shadow-0 {
  box-shadow: none !important;
}

.min-width-0 {
  min-width: 0px !important;
}

.max-width-0 {
  max-width: 0px !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-h-100vh {
  min-height: 100vh !important;
}

.min-h-50vh {
  min-height: 50vh;
}

.width-auto {
  width: auto;
}

/*---------- height, Width % classes --------*/
.h-100 {
  height: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.h-25 {
  height: 25% !important;
}

.h-20 {
  height: 20% !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.h-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

/*---------- End --------*/

/*---------- Overflow classes --------*/
.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-initial {
  overflow-y: initial;
}

.overflow-x-initial {
  overflow-x: initial;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

/*---------- End --------*/

/*---------- text classes --------*/
.text-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-underline-hover:hover {
  text-decoration: underline !important;
  @extend .cursor-pointer;
  color: $themeColorPrimary;
}

.text-initial {
  text-transform: initial !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-truncate {
  @include text-truncate;
}

.text-truncate-none {
  white-space: initial !important;
  @extend .overflow-visible;
  text-overflow: initial !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.disabled {
  @extend .cursor-not-allowed;
  @include custom-opacity(0.6);
  pointer-events: none;
}

/*---------- End --------*/

/*---------- Opactity --------*/
.opacity-1 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

/*---------- End --------*/

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

/*---------- custom colors --------*/
.text-white {
  color: $white !important;
}

.text-color {
  color: $text-color !important;
}

.text-light-content {
    color: $content-color-light !important;
}

.text-content {
  color: $content-color !important;
}

.text-orange {
  color: $orange !important;
}

.text-indigo {
  color: $indigo !important;
}

.text-pink {
  color: $pink !important;
}

.text-primary {
  @include color($themeColorPrimary !important);
}

.text-secondary {
  @include color($themeColorSecondary !important);
}

.text-info {
  @include color($info !important);
}

.text-dark {
  @include color($text-color !important);
}

.text-gray {
  @include color($text-dark-gray !important);
}

.text-warning {
  @include color($warning-color !important);
}

.text-success {
  @include color($themeColorPrimary !important);
}

.text-danger {
  @include color($danger-color !important);
}
/*--------- End -----------*/

/*---------- custom backgrounds --------*/
.bg-primary {
  background-color: $themeColorPrimary;
}

.bg-secondary {
  background-color: $themeColorSecondary;
}

.bg-dark {
  background-color: $text-color;
}

.bg-danger {
  background-color: $danger-color;
}

.bg-success {
  background-color: $success;
}

.bg-info {
  background-color: $info;
}

.bg-warning {
  background-color: $warning-color;
}

.bg-default {
  background-color: $info;
}

.bg-white {
  background-color: $white;
}

.bg-green {
  background-color: $green;
}

.bg-orange {
  background-color: $orange;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-indigo {
  background-color: $indigo;
}

.bg-pink {
  background-color: $pink;
}

.bg-transparent {
  background-color: transparent;
}

.bg-light-green {
  background-color: $light-green-bg;
}

.bg-light-red {
  background-color: $light-red-bg;
}

.bg-light-blue {
  background-color: $card-light-blue-bg;
}

.light-blue-bg {
  background-color: $light-blue-bg;
}

.light-gray-bg {
  background: $light-gray-bg;
}

/*---------- End --------*/

/*---------- border-colors --------*/
.border-primary {
  @include border(border, 1px solid $themeColorPrimary);
}

.border-secondary {
  @include border(border, 1px solid $themeColorSecondary);
}

.border-info {
  @include border(border, 1px solid $info);
}

.border-danger {
  @include border(border, 1px solid $danger-color);
}

.border-success {
  @include border(border, 1px solid $success);
}

.border-dark {
  @include border(border, 1px solid $text-color);
}

.border-warning {
  @include border(border, 1px solid $warning-color);
}

/*---------- End --------*/

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tooltip {
  @include custom-z-index(99 !important);
  @extend .overflow-visible;
  @extend .f-12;
}

/*---------- custom scroll bar --------*/
::-webkit-scrollbar {
  @include custom-height-width(3px, 3px);
}

::-webkit-scrollbar-track {
  @include custom-box-shadow(inset 0 0 3px rgba(0, 0, 0, 0.4));
  @extend .border-radius-5;
}

::-webkit-scrollbar-thumb {
  @include background($themeColorPrimary);
  @extend .border-radius-5;

  &:hover {
    @include background($content-color);
  }
}
/*---------- End --------*/

/*---------- positions --------*/

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/*---------- End --------*/

/*---------- Float-classes --------*/
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

/*---------- End --------*/

.background-none {
  background: none;
}


.margin-auto {
  margin: 0px auto !important;
}

.pointer-events-none {
  pointer-events: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

.d-flex-margin {
  @include margin(0px, -10px, 0px, -10px);
}

.image-wrapper {
  @include image-wrapper-custom;
}

// Word Wrap
.word-wrap-normal {
  word-wrap: normal;
}

.word-wrap-break {
  word-wrap: break-word;
}
// Word Wrap End

// Circle
.circle {
  @include border-radius-50;
}

// transitions
.transition-3s {
  @include transition-3s;
}

.transition-5s {
  @include transition-5s;
}

.transition-1s {
  @include transition-1s;
}
// transitions End

// positions
.position-default {
  @include position-all-side;
}

.top-right-center {
  @include transform-top-right-center-align;
}

.top-center {
  @include transform-top-center-align;
}

.top-left-center {
  @include transform-left-center-align;
}

.center {
  @include transform-center-align;
}
// positions End

.img-responsive {
  @extend .w-100;
  @extend .height-auto;
}