.footer83{
  @include size-color(13px, $text-color);
  @include flex-align-justify(center, space-between);
  @extend .padding-x-24;
  @extend .padding-y-12;
  @extend .bg-white;
  position: sticky;
  bottom: 0px;
  @extend .border-top-1;
  z-index:  1;
  
  ul {
    @extend .align-items-center;
    @extend .list-style-none;
    
    li {
      @include border(border-right, 1px solid $border-color-dark);
      @include custom-line-height(12px);
      @extend .padding-x-15;
      @include color($text-color);
      
      &:last-of-type {
        @extend .border-right-0;
        @extend .padding-right-0;
      }
      
      &:first-of-type {
        @extend .padding-left-0;
      }
      
      a {
        @include custom-line-height(12px);
        @extend .text-decoration-none;
        @include color($text-color);
        
        &:hover {
          @extend .text-underline;
        }
      }
      
      .custom-footer83-tooltip {
        @extend .display-none;  
        position: absolute;
        bottom: 34px;
        min-width: 300px;

        .footer-detail-card83 {
          position: relative;
          box-shadow: 0px 0px 28px 5px rgba(0, 0, 0, 0.1) !important;
          @extend .w-100;
          @extend .margin-0;

          &:after {
            content: "";
            position: absolute;
            z-index: 999;
            border-top: 15px solid #fff;
            border-bottom: 15px solid transparent;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            left: 30px;
            display: block;
          }

          .footer-info {
            @extend .display-flex;
            @extend .align-items-center;
            h6 {
              // @include color($text-color);
              @include size-weight-color(12px, 600, $text-color);
              @extend .margin-bottom-2;
              @extend .margin-top-2;
              @extend .margin-right-5;
              white-space: nowrap;
              &:last-child{
              @extend .margin-bottom-0;
              } 
            }
            p{  
                font-weight: 500; 
                @include text-truncate;
                max-width: 180px;
            }
          }
        }

      }
      &:hover {
        .custom-footer83-tooltip {
          @extend .display-flex;
        }
      }
    }
  }
}