.content-wrapper83 {
  @extend .position-relative;
  @extend .h-100vh;
  @include padding(0px, 0px, 0px, 0px);
  @extend .overflow-hidden;

  .content-items83 {
    @include padding(0px, 0px, 0px, 220px);
    @extend .h-100vh;
  }

  .loading-box {
    @include square(100%, 100%);
    @extend .flex-center-align;
    @include size-color(30px, $themeColorPrimary);
    @extend .text-center;

    h3 {
      @extend .margin-y-10;
    }
  }

  .content-body83 {
    @extend .position-relative;
    @include padding(24px, 24px, 24px, 24px);
    @extend .padding-bottom-15;
    @extend .border-radius-16;
    @include custom-height(calc(100vh - 124px));
    
    &.custom-padding-top {
      @include padding(30px, 30px, 10px, 30px);
    }

    .infinite-scroll-component {
      @extend .overflow-visible;
    }
  }

  .content-footer83 {
    @include card-box;
    @include padding(5px, 10px, 5px, 10px);
    @include custom-position-bottom-left-right(5px, 230px, 12px);

    ul {
      li {
        @extend .padding-y-10;
        @extend .border-right-1;
        @include size-color(12px, $content-color);

        &:last-of-type {
          @extend .border-0;
        }
      }
    }
  }
}