.toggle83 {
  .switch {
    position: relative;
    display: inline-block;
    width: 47px;
    height: 21px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      
      &:checked {
        & + .slider {
          background-color: $themeColorPrimary;
          &:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
        }
      }
      &:focus {
        & + .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $danger-color;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      &.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
      }
    }
  }
}
